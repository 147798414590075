<template>
  <div>
    <!-- If we're creating and not editing -->
    <div v-if="model === null">
      <!-- Show input to select the influencer -->
      <div class="contain-select-width">
        <!-- Option to select the influencer here -->
        <profile-selector
          :overview="overview"
          label="Search Profile"
          type="search"
          outlined
          dense
        />
      </div>
    </div>

    <!-- Show a date selector input -->
    <v-row class="mt-3">
      <!-- Date picker column -->
      <v-col cols="12">
        <!-- Use the date-picker -->
        <v-menu
          v-model="dialog.shouldShowDate"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formData.date ? dayjs(formData.date).format('ll') : null"
              :hide-details="!$v.formData.date.$anyError"
              :error-messages="$v.formData.date.$anyError ? ['Please select a date'] : null"
              @blur="$v.formData.date.$touch"
              label="Posted Date"
              prepend-inner-icon="today"
              v-bind="attrs"
              v-on="on"
              outlined
              readonly
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            color="primary"
            show-current
            show-adjacent-months
            :min="overview.model.start_date"
            :max="overview.model.end_date"
            v-model="formData.date"
            @input="dialog.shouldShowDate = false"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <!-- Time picker column -->
      <!-- <v-col>
        <v-menu
          v-model="dialog.shouldShowTime"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formData.time"
              :hide-details="!$v.formData.time.$anyError"
              :error-messages="$v.formData.time.$anyError ? ['Please select a time'] : null"
              @blur="$v.formData.time.$touch"
              label="Time"
              prepend-inner-icon="schedule"
              v-bind="attrs"
              v-on="on"
              outlined
              readonly
              dense
            ></v-text-field>
          </template>
          <v-time-picker
            color="primary"
            v-model="formData.time"
            @input="dialog.shouldShowTime = false"
          ></v-time-picker>
        </v-menu>
      </v-col> -->
    </v-row>

    <!-- To show thumbnail input and preview -->
    <div class="mt-6">
      <!-- The file input for stories list -->
      <input
        type="file"
        name="crm-stories-form-thumbnail"
        accept=".png, .jpg, .jpeg"
        class="d-none"
        ref="thumbnailFileInput"
        @change="handleThumbnailChange"
      />

      <!-- Show the preview if available -->
      <v-img
        v-if="formData.thumbnail.preview || formData.thumbnail.live"
        :src="formData.thumbnail.preview || formData.thumbnail.live"
        @click="triggerThumbnailSelection"
        height="250"
        contain
      />

      <!-- Otherwise show the input instead -->
      <div
        v-else
        class="thumbnail-item accent d-flex align-center justify-center text-subtitle-2 rounded"
        style="height: 250px;"
        @click="triggerThumbnailSelection"
      >
        Upload Story Image
      </div>

      <!-- If there are validation errors, show a message -->
      <div
        v-if="$v.formData.thumbnail.file.$anyError"
        class="mt-2 text-center red--text"
      >
        Please select a thumbnail image under 5MB
      </div>
    </div>

    <!-- Show a divider in between -->
    <v-divider class="mb-6 mt-6" />

    <!-- Show a text for this section -->
    <div class="text-overline mb-3">
      Statistics
    </div>

    <!-- Show the input options grid -->
    <v-row>
      <!-- Reach -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.reach"
          color="primary"
          label="Reach"
          type="number"
          :hide-details="!$v.formData.reach.$anyError"
          :error-messages="$v.formData.reach.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.reach.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Impressions -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.impressions"
          color="primary"
          label="Impressions"
          type="number"
          :hide-details="!$v.formData.impressions.$anyError"
          :error-messages="$v.formData.impressions.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.impressions.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Replies -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.replies"
          color="primary"
          label="Replies"
          type="number"
          :hide-details="!$v.formData.replies.$anyError"
          :error-messages="$v.formData.replies.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.replies.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Reactions -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.reactions"
          color="primary"
          label="Reactions"
          type="number"
          :hide-details="!$v.formData.reactions.$anyError"
          :error-messages="$v.formData.reactions.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.reactions.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Shares -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.shares"
          color="primary"
          label="Shares"
          type="number"
          :hide-details="!$v.formData.shares.$anyError"
          :error-messages="$v.formData.shares.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.shares.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Sticker Taps -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.sticker_taps"
          color="primary"
          label="Sticker Taps"
          type="number"
          :hide-details="!$v.formData.sticker_taps.$anyError"
          :error-messages="$v.formData.sticker_taps.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.sticker_taps.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>

      <!-- Website Clicks -->
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="formData.website_clicks"
          color="primary"
          label="Website Clicks"
          type="number"
          :hide-details="!$v.formData.website_clicks.$anyError"
          :error-messages="$v.formData.website_clicks.$anyError ? ['Invalid value'] : null"
          @blur="$v.formData.website_clicks.$touch"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>

    <!-- Show the actions here -->
    <div class="d-flex justify-end mt-6">
      <v-btn
        depressed
        color="primary"
        :disabled="isMakingRequest"
        :loading="isMakingRequest"
        @click="validateInputs"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
// Import helper functions
import { required, minValue, maxValue, numeric } from "vuelidate/lib/validators"

// Import children components
const ProfileChip = () => import(/* webpackChunkName: "profile-chip" */ "@/blocks/common/ProfileChip")
const ProfileSelector = () => import(/* webpackChunkName: "crm-profile-selector" */ "@/components/crm/ProfileSelector.vue")

// Define default form values generator
const generateFormData = () => ({
  influencerId: null,

  date: "",
  time: "00:00",
  reach: "",
  impressions: "",
  replies: "",
  reactions: "",
  shares: "",
  sticker_taps: "",
  website_clicks: "",
  thumbnail: {
    preview: null,
    file: null,
  },

  screenshots: []
})

// Export the SFC
export default {
  // Name of the component
  name: "StoryForm",

  // Register children components
  components: {
    ProfileChip,
    ProfileSelector
  },

  // Accept incoming data from parent
  props: {
    overview: {
      type: Object,
      required: true
    },

    model: {
      type: Object,
      required: false,
      default: null
    },

    isMakingRequest: {
      type: Boolean,
      required: false,
      default: false
    },
  },

  // Define local data variables
  data: () => ({
    // Initialize the form data
    formData: generateFormData(),

    // Whether or not the dialogs are visible
    dialog: {
      shouldShowDate: false,
      shouldShowTime: false
    }
  }),

  // Define readonly computed properties
  computed: {
    /**
     * Get the selected influencer
     *
     * @returns {Object}
     */
     selectedInfluencer() {
      return this.$store.getters["campaignTracking/selectedInfluencerById"](this.overview.model.id)
    }
  },

  // Define vuelidate validators
  validations() {
    const object = {
      formData: {
        reach: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(100_000_000_000)
        },
        impressions: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(100_000_000_000)
        },
        replies: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(1_000_000_000)
        },
        reactions: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(1_000_000_000)
        },
        shares: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(1_000_000_000)
        },
        sticker_taps: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(1_000_000_000)
        },
        website_clicks: {
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(1_000_000_000)
        },

        // Validate date
        date: {
          required,
          between: (v) => dayjs(v).isBetween(dayjs(this.overview.model.start_date), dayjs(this.overview.model.end_date), null, "[]")
        },

        // Validate time
        time: {
          required,
        },

        // Validate the selected influencer
        influencerId: {
          required
        },

        // Validation for thumbnail
        thumbnail: {
          file: {
            maxSize: (value) => {
              return value ? value.size <= 5_000_000 : true // Maximum 5MB in size
            },
            mimeType: (value) => {
              return value ? ["image/png", "image/jpeg"].includes(value.type) : true
            }
          }
        }
      }
    }

    // If we're editing
    if (this.model) {
      // Remove the influencer id validation
      delete object.formData.influencerId
    }

    // Return the object
    return object
  },

  // Watch for any changes
  watch: {
    selectedInfluencer: {
      handler() {
        // If we have a selected influencer
        if (this.selectedInfluencer) {
          // Set it as the influencer id
          this.formData.influencerId = this.selectedInfluencer.id
        }
      },

      // Watch deeply
      deep: true
    }
  },

  // Define local method functions
  methods: {
    /**
     * Validate the form inputs
     *
     * @returns {void}
     */
    async validateInputs() {
      // Run vuelidate validations
      await this.$v.formData.$touch()

      // Check if there's any error
      if (this.$v.formData.$anyError) {
        // Show a snackbar message
        this.$store.dispatch("toasts/add", { text: "Please enter valid values" })

        // Stop further execution
        return
      }

      // Otherwise, create a formData
      const formData = new FormData()

      // Append statistic values
      formData.append("reach", this.formData.reach || 0)
      formData.append("impressions", this.formData.impressions || 0)
      formData.append("replies", this.formData.replies || 0)
      formData.append("reactions", this.formData.reactions || 0)
      formData.append("shares", this.formData.shares || 0)
      formData.append("sticker_taps", this.formData.sticker_taps || 0)
      formData.append("website_clicks", this.formData.website_clicks || 0)

      // If we're creating
      if (this.model === null) {
        // Add the influencer id as well
        formData.append("tracker_influencer_id", this.formData.influencerId)
      }

      // Add the date and time
      formData.append("posted_at", dayjs(`${this.formData.date} ${this.formData.time}`).format("YYYY-MM-DD HH:mm:ss"))

      // Check if there's a thumbnail
      if (this.formData.thumbnail.file) {
        // Append the thumbnail
        formData.append("thumbnail", this.formData.thumbnail.file)
      }

      // Emit the event with this data
      this.$emit("submit", formData)
    },

    /**
     * Trigger the thumbnail selection
     *
     * @returns {void}
     */
    triggerThumbnailSelection() {
      // If we're making a request
      if (this.isMakingRequest) {
        // Stop further execution
        return
      }

      // Otherwise, trigger input click
      this.$refs.thumbnailFileInput && this.$refs.thumbnailFileInput.click()
    },

    /**
     * Handle the event when the thumbnail changes in input
     *
     * @param {Object} event
     * @returns {void}
     */
     handleThumbnailChange(event) {
      // Check if the event has any files in it
      if (!event.target.files || !event.target.files.length) {
        // Show an error message
        this.$store.dispatch("toasts/add", { text: "No file selected" })

        // End the execution
        return
      }

      // Add this to the form data
      this.formData.thumbnail.file = event.target.files[0]

      // If an image is selected
      if (this.formData.thumbnail.file && this.formData.thumbnail.file instanceof File) {
        this.formData.thumbnail.preview = URL.createObjectURL(this.formData.thumbnail.file)
      }
      // Otherwise, set it to null
      else {
        this.formData.thumbnail.preview = null
      }
    },
  },

  /**
   * As soon as the component data is ready
   *
   * @returns {void}
   */
  created() {
    // If we have a model, use it to populate the form data
    if (this.model) {
      // Use dayjs to get date and time from string
      const date = dayjs(this.model.posted_at).format("YYYY-MM-DD")
      const time = dayjs(this.model.posted_at).format("HH:mm")

      // Populate the form data
      this.formData = {
        influencerId: this.model.tracker_influencer_id,

        date,
        time,
        reach: this.model.reach || 0,
        impressions: this.model.impressions || 0,
        replies: this.model.replies || 0,
        reactions: this.model.reactions || 0,
        shares: this.model.shares || 0,
        sticker_taps: this.model.sticker_taps || 0,
        website_clicks: this.model.website_clicks || 0,
        thumbnail: {
          live: this.model.thumbnail ? this.storageUrl(this.model.thumbnail) : null,
          preview: null,
          file: null,
        },
        screenshots: (this.model.screenshots || []).map((item) => ({
          live: this.storageUrl(item),
          preview: null,
          file: null,
        }))
      }
    }
    // Otherwise
    else {
      // Select the campaign start date as default
      this.formData.date = this.overview.model.start_date
    }

    // If we have a selected influencer
    if (this.selectedInfluencer) {
      // Set it as the influencer id
      this.formData.influencerId = this.selectedInfluencer.id
    }
  }
}
</script>
